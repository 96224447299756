/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_WHITE,
	colors,
	media,
	THEME_PERIWINKLE,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_1UP,
	SHOWCASE_LAYOUT_2UP,
} from '../../components/content/Showcase'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.cloudkitchens.next

type Props = {
	accentTheme: string
}

const CloudKitchensPage: FC<Props> = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			otterPerf: file(relativePath: { eq: "cloud-kitchens/otter-perf.png" }) {
				...ImageFluidFull
			}
			styleMosaic: file(
				relativePath: { eq: "cloud-kitchens/style-mosaic.png" }
			) {
				...ImageFluidFull
			}
			onboardingsLight: file(
				relativePath: { eq: "cloud-kitchens/onboardings-light.png" }
			) {
				...ImageFluidFull
			}
			onboardingsDark: file(
				relativePath: { eq: "cloud-kitchens/onboardings-dark.png" }
			) {
				...ImageFluidFull
			}
			buttonImplemenation: file(
				relativePath: { eq: "cloud-kitchens/button-implementation.png" }
			) {
				...ImageFluidFull
			}
			buttonDocs: file(relativePath: { eq: "cloud-kitchens/button-docs.png" }) {
				...ImageFluidFull
			}

			motifUX: file(relativePath: { eq: "cloud-kitchens/motif-ux.png" }) {
				...ImageFluidFull
			}
			motifVisual: file(
				relativePath: { eq: "cloud-kitchens/motif-visual.png" }
			) {
				...ImageFluidFull
			}

			sizingOptions: file(
				relativePath: { eq: "cloud-kitchens/sizing-options.png" }
			) {
				...ImageFluidFull
			}

			tableDocs: file(relativePath: { eq: "cloud-kitchens/table-docs.png" }) {
				...ImageFluidFull
			}
			orderIssues: file(
				relativePath: { eq: "cloud-kitchens/order-issues.png" }
			) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<Fragment>
			<Helmet>
				<title>CloudKitchens</title>
				<meta content="CloudKitchens Case Study" property="og:title" />
				<meta content="CloudKitchens Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="A universal design system to empower UX designers in a multi-brand environment."
				/>
				<meta
					name="description"
					content="A universal design system to empower UX designers in a multi-brand environment."
				/>
				<meta
					content="https://freeassociation.com/share/cloudkitchens.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/cloudkitchens.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">CloudKitchens</h1>
							<p className="--headline">
								Simple ingredients, endless combinations.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									CloudKitchens is a ghost kitchen and virtual restaurant
									company, with software to manage every aspect of the delivery
									business. Our challenge was to create a universal design
									system that could empower UX designers in a multi-brand
									environment. To do that, we needed to standardize how
									components were defined, built, and implemented—ensuring
									flexibility in the face of unpredictable product and brand
									challenges.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>UI &amp; Visual Design</li>
									<li>Brand Systems &amp; Guidelines</li>
									<li>Technical Strategy </li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_1UP}
						chrome={true}
						images={[data.otterPerf.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								To create a universal system, we first needed to audit the
								existing range of brands within the product ecosystem. Together,
								we helped tease out the similarities and differences between the
								various brand systems, which ultimately led to a foundational
								set of themeable brand tokens.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.styleMosaic.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						chrome={false}
						layout={SHOWCASE_LAYOUT_2UP}
						images={[
							data.onboardingsLight.childImageSharp.fluid,
							data.onboardingsDark.childImageSharp.fluid,
						]}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									To help designers work across the platform using the same core
									system, we worked with the team to standardize how components
									are defined, built, and implemented.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Use Cases and Variants">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										How will designers leverage this component across the
										platform? What kinds of variants are needed to fulfill all
										of these use cases?
									</p>
								</Text>
							</Highlight>
							<Highlight title="Composability">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										How do we make this component useful and not stymie product
										innovation, while still providing all of the required
										states?
									</p>
								</Text>
							</Highlight>
							<Highlight title="Stylistic Flexibility">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										How might we employ the brand tokens to make this component
										flexible in a multi-brand environment?
									</p>
								</Text>
							</Highlight>
							<Highlight title="Designer UX">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										How will designers want to use or configure this component
										in Figma? What’s the easiest way to capture designer intent?
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						chrome={false}
						layout={SHOWCASE_LAYOUT_1UP}
						images={[data.buttonImplemenation.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.buttonDocs.childImageSharp.fluid}
					/>
				</div>
			</Slide>

			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<blockquote>
								<p className="--large">
									“Always approach a design system like any other digital
									product—how will it make the designer's experience better?”
								</p>
								<footer>
									<p className="--small">
										<strong>Kyle Truscott</strong>
										<br />
										Senior Principal UX Engineer
									</p>
								</footer>
							</blockquote>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								As each component was implemented, we experimented with
								different Figma variant and property configurations. In some
								instances, visual choice was restricted through nested
								components, while in other situations designers were given
								control over every visual detail. Most often, it made sense to
								embrace a hybrid approach.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						chrome={false}
						layout={SHOWCASE_LAYOUT_2UP}
						images={[
							data.motifUX.childImageSharp.fluid,
							data.motifVisual.childImageSharp.fluid,
						]}
					/>
				</Container>
			</Slide>

			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								As we moved to higher-order components, it became critical to
								design with flexibility in mind. Guardrails were established by
								clearly defining what each pattern could contain, but without
								making any assumptions about what designers were actually
								composing.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						chrome={false}
						layout={SHOWCASE_LAYOUT_1UP}
						images={[data.sizingOptions.childImageSharp.fluid]}
					/>
				</Container>
			</Slide>

			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.tableDocs.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.orderIssues.childImageSharp.fluid}
					/>
				</div>
			</Slide>

			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(CloudKitchensPage, THEME_PERIWINKLE)
